<template>
  <div class="growth-value">
    <el-form-item label="会员成长值：">
      <div class="growth-item">
        <el-checkbox v-model="grow_value[0].login" true-label="1" false-label="0">每日登录送</el-checkbox>
        <el-input v-model="grow_value[0].num" :min="0" type="number"></el-input>
        <span>成长值</span>
      </div>
      <div class="growth-item">
        <el-checkbox v-model="grow_value[1].act" true-label="1" false-label="0">首次激活会员卡送</el-checkbox>
        <el-input v-model="grow_value[1].num" :min="0" type="number"></el-input>
        <span>成长值</span>
      </div>
      <div class="growth-item">
        <el-checkbox v-model="grow_value[2].pay" true-label="1" false-label="0">每消费1元送</el-checkbox>
        <el-input v-model="grow_value[2].num" :min="0" type="number"></el-input>
        <span>成长值</span>
      </div>
      <div class="growth-item">
        <el-checkbox v-model="grow_value[3].recharge" true-label="1" false-label="0">每充值1元送</el-checkbox>
        <el-input v-model="grow_value[3].num" :min="0" type="number"></el-input>
        <span>成长值</span>
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: ['growValue'],
  data() {
    return {
      grow_value: [
        {
          login: '0',
          num: ''
        },
        {
          act: '0',
          num: ''
        },
        {
          pay: '0',
          num: ''
        },
        {
          recharge: '0',
          num: ''
        }
      ]
    }
  },
  watch: {
    growValue(val) {
      this.grow_value = val
    }
  },
  methods: {
    handleData() {}
  }
}
</script>

<style lang="less" scoped>
.growth-value {
  padding: 20px 0 0 30px;
  .growth-item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .el-input {
      width: 150px;
      margin-right: 20px;
    }
    .el-checkbox {
      width: 150px;
    }
  }
}
</style>
