<template>
  <div class="card-interface">
    <div class="form">
      <div class="tips">温馨提示：本页面设置为最初级会员卡等级权限，可在不开通后续会员等级的情况下单独使用。</div>
      <el-form-item label="* 会员卡名称：">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="* 卡片封面：" class="card-cover">
        <el-radio-group v-model="form.cover" @change="coverChange">
          <div class="box">
            <el-radio :label="1">封面模板</el-radio>
            <ul class="cover">
              <el-image :src="cover_template"></el-image>
            </ul>
          </div>
          <div class="box">
            <el-radio :label="2">自定义上传图片</el-radio>
            <span class="sp">建议图片尺寸695px*368px，大小不超过1M的png/jpg图片</span>
          </div>
        </el-radio-group>
        <ReadyUploadSource
          v-show="form.cover == 2"
          @getSource="getCoverImg"
          @removeThis="removeCoverImg"
          :path="form.cover_image"
          :showStyle="{
            width: '150px',
            height: '100px',
          }"
          :maxSize="1024 * 1024"
        ></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="激活会员卡领取：" class="card-activation">
        <div class="box">
          <el-checkbox label="全部商品包邮" v-model="form.act_reward.all_goods_by" true-label="1" false-label="0"></el-checkbox>
        </div>
        <div class="box zhekou">
          <div>
            <el-checkbox label="商品享受折扣" v-model="form.act_reward.goods_idscount" true-label="1" false-label="0"></el-checkbox>
            <el-input
              v-model="form.act_reward.goods_idscount_num"
              @change="$event => ($store.state.utils.member_discount = $event)"
              @input="zhekouChange"
              :min="0.1"
              :max="9.9"
              type="number"
              placeholder="数值"
            ></el-input>
            <span class="sp">折 可填写0.1-9.9折</span>
          </div>
          <p style="color: #909399">（注意：会员卡折扣和优惠券可同时使用，先减优惠券，后计算会员卡折扣）</p>
        </div>
        <div class="box">
          <el-checkbox label="新会员激活送优惠" v-model="form.act_reward.is_coupons" true-label="1" false-label="0" style="width: 160px"></el-checkbox>
          <el-button type="text" @click="handleshowAddCoupons" v-show="form.act_reward.is_coupons">{{ selectCouponList.length ? `已添加${selectCouponList.length}张` : '添加' }}</el-button>
        </div>
        <div class="box">
          <el-checkbox label="新会员激活送" v-model="form.act_reward.is_money" true-label="1" false-label="0"></el-checkbox>
          <el-input v-model="form.act_reward.money" :min="0" type="number" placeholder="数值"></el-input>
          <span class="sp">元余额</span>
        </div>
      </el-form-item>
      <el-form-item label="激活信息设置：">
        <div class="phone">
          <span>手机号（用户必须填项）</span>
          <el-button type="text" @click="handleshowAddInfo">{{ selectInfoList.length ? `已添加${selectInfoList.length}个` : '添加' }}</el-button>
        </div>
        <div class="opRequired">
          <span>必填项</span>
          <div class="opRequired-box">
            <div v-for="(item, index) in opRequired" :key="index">
              <el-checkbox :label="item.info.val" v-model="item.info.is_select" @change="changeRequired(index)"></el-checkbox>
              <i class="el-icon-circle-close" v-if="item.info.is_custom == 1" @click="removeOpRequired(index)"></i>
            </div>
          </div>
        </div>
        <div class="optional">
          <span>选填项</span>
          <div class="optional-box">
            <div v-for="(item, index) in optional" :key="index">
              <el-checkbox :label="item.info.val" v-model="item.info.is_select" @change="changeOptional(index)"></el-checkbox>
              <i class="el-icon-circle-close" v-if="item.info.is_custom == 1" @click="removeOptional(index)"></i>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="使用须知：">
        <el-input v-model="form.content" type="textarea" :rows="8" placeholder="请输入内容"></el-input>
      </el-form-item>
    </div>
    <div class="interface-box">
      <div class="card-cover">
        <div class="con">
          <el-image :src="cover_path"></el-image>
          <div class="con-box">
            <div class="top">
              <div class="card-name">
                <el-image :src="store_info.logo || require('../../../assets/user.png')"></el-image>
                <div>
                  <p>{{ store_info.name }}</p>
                  <p>{{ form.title || '会员卡名称' }}</p>
                </div>
              </div>
            </div>
            <div class="bot">
              <div class="card-num">
                <p>有效期：2021-12-01</p>
              </div>
              <span>使用中</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-interest">
        <ul>
          <li>
            <el-image :src="require('@/assets/image/card/icon1.png')"></el-image>
            <span>{{ Number(form.act_reward.all_goods_by) ? '全部商品包邮' : '未解锁' }}</span>
          </li>
          <li>
            <el-image :src="require('@/assets/image/card/icon2.png')"></el-image>
            <span>{{ Number(form.act_reward.goods_idscount) ? form.act_reward.goods_idscount_num + '折' : '未解锁' }}</span>
          </li>
          <li>
            <el-image :src="require('@/assets/image/card/icon3.png')"></el-image>
            <span>{{ Number(form.act_reward.is_coupons) ? `赠送${selectCouponList.length}张优惠券` : '未解锁' }}</span>
          </li>
          <li>
            <el-image :src="require('@/assets/image/card/icon4.png')"></el-image>
            <span>{{ Number(form.act_reward.is_money) ? `赠送${form.act_reward.money}元余额` : '未解锁' }}</span>
          </li>
          <li>
            <el-image :src="require('@/assets/image/card/icon5.png')"></el-image>
            <span>未解锁</span>
          </li>
          <li>
            <el-image :src="require('@/assets/image/card/icon6.png')"></el-image>
            <span>未解锁</span>
          </li>
        </ul>
      </div>
      <!-- <div class="footer">
        <div>
          <span>通用门店</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div>
          <span>会员卡详情</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div>
          <span>会员卡续期</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <el-button type="info">立即充值</el-button>
      </div> -->
    </div>
    <el-dialog title="赠送优惠券" :visible.sync="showAddCoupons" width="600px" center>
      <div class="coupon-content">
        <ul class="add-coupon-list">
          <li class="coupon-item" v-for="(item, index) in addCouponList" :key="index">
            <span>赠送优惠券：</span>
            <el-select v-model="item.coupon_id" filterable placeholder="请选择">
              <el-option v-for="item in couponList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <span style="margin: 0 10px">赠送</span>
            <el-input v-model="item.coupon_num"></el-input>
            <span>张</span>
            <i class="el-icon-circle-close" @click="removeAddCouponList(index)"></i>
          </li>
        </ul>
      </div>
      <div style="text-align: center">
        <el-button type="text" @click="pushAddCouponList">添加优惠券</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddCoupons = !1">取 消</el-button>
        <el-button type="primary" @click="confirmshowAddCoupons">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="激活信息添加" :visible.sync="showAddInfo" width="760px" center>
      <div class="info-content">
        <ul class="add-info-list">
          <li class="info-item" v-for="(item, index) in addInfoList" :key="index">
            <el-input v-model="item.name" placeholder="请添加信息"></el-input>
            <span style="margin: 0 10px">必填</span>
            <el-select v-model="item.require" filterable placeholder="请选择">
              <el-option v-for="options in item.options" :key="options.val" :label="options.label" :value="options.val"></el-option>
            </el-select>
            <span style="margin: 0 10px">类型</span>
            <el-select v-model="item.type_val" filterable placeholder="请选择">
              <el-option v-for="options in item.type" :key="options.val" :label="options.label" :value="options.val"></el-option>
            </el-select>
          </li>
        </ul>
        <el-button type="text" @click="pushAddInfoList">添加激活信息</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddInfo = !1">取 消</el-button>
        <el-button type="primary" @click="confirmshowAddInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import pinyin from 'pinyin';
export default {
  components: {
    ReadyUploadSource,
  },
  props: ['cardInfo'],
  data() {
    return {
      showAddCoupons: !1,
      showAddInfo: !1,
      couponList: [],
      addCouponList: [], //添加的优惠券
      selectCouponList: [], //选中的添加优惠券
      addInfoList: [], //添加的信息
      selectInfoList: [], //选中添加的信息
      //必填项
      opRequired: [
        {
          name: '用户姓名',
          info: {
            type: 1,
            val: '用户姓名',
            require: 1,
            is_select: !1,
            is_custom: 0, //是否自定义
          },
        },
        {
          sex: '用户性别',
          info: {
            type: 1,
            val: '用户性别',
            require: 1,
            is_select: !1,
            is_custom: 0,
          },
        },
        {
          occupation: '用户职业',
          info: {
            type: 1,
            val: '用户职业',
            require: 1,
            is_select: !1,
            is_custom: 0,
          },
        },
        {
          brith: '用户生日',
          info: {
            type: 1,
            val: '用户生日',
            require: 1,
            is_select: !1,
            is_custom: 0,
          },
        },
      ],
      //选填项
      optional: [
        {
          name: '用户姓名',
          info: {
            type: 1,
            val: '用户姓名',
            require: 0,
            is_select: !1,
            is_custom: 0,
          },
        },
        {
          sex: '用户性别',
          info: {
            type: 1,
            val: '用户性别',
            require: 0,
            is_select: !1,
            is_custom: 0,
          },
        },
        {
          occupation: '用户职业',
          info: {
            type: 1,
            val: '用户职业',
            require: 0,
            is_select: !1,
            is_custom: 0,
          },
        },
        {
          brith: '用户生日',
          info: {
            type: 1,
            val: '用户生日',
            require: 0,
            is_select: !1,
            is_custom: 0,
          },
        },
      ],
      cover_path: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/vip-cover.png',
      cover_template: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/vip-cover.png',
      form: {
        title: '', //会员卡名称
        cover: 1, //1模版 2自定义图片
        cover_template: 0, //封面模版
        cover_image: '', //cover_image
        //激活奖励
        act_reward: {
          all_goods_by: '0', //是否包邮 1包邮 0不包邮
          goods_idscount: '0', //是否享受打折 1打折 0不打折
          goods_idscount_num: '', //几折
          is_coupons: '0', //是否奖励优惠券
          is_money: '0', //是否送余额
          money: '', //余额
          coupons_data: [], //{} coupon_id 优惠券id  coupon_num优惠券数量
        },
        act_set: {},
        content: '', //使用须知
      },
    };
  },
  computed: {
    store_info() {
      return this.$store.getters.shopInfo;
    },
  },
  watch: {
    cardInfo(val) {
      if (val.act_set.require) {
        for (let i in val.act_set.require) {
          val.act_set.require[i].info.is_select = JSON.parse(val.act_set.require[i].info.is_select);
        }
        this.opRequired = val.act_set.require;
      }
      if (val.act_set.norequire) {
        for (let i in val.act_set.norequire) {
          val.act_set.norequire[i].info.is_select = JSON.parse(val.act_set.norequire[i].info.is_select);
        }
        this.optional = val.act_set.norequire;
      }
      if (val.cover == 1) {
        this.cover_path = this.cover_template;
      } else {
        this.cover_path = val.cover_image;
      }
      this.selectCouponList = val.act_reward.coupons_data || [];
      this.form = val;
    },
  },
  created() {
    this.getCouponList();
  },
  methods: {
    //处理接口请求需要的数据
    handleData() {
      try {
        let form = this.form;
        let selectCouponList = this.selectCouponList;
        let opRequired = this.opRequired;
        let optional = this.optional;
        if (!form.title) throw '请填写会员卡名称';
        if (form.cover == 2 && !form.cover_image) throw '请添加会员卡封面图片';
        if (Number(form.act_reward.goods_idscount) && !form.act_reward.goods_idscount_num) throw '请填写商品折扣';
        if (Number(form.act_reward.is_coupons) && !selectCouponList.length) throw '请添加优惠券';
        if (Number(form.act_reward.is_money) && !form.act_reward.money) throw '请填写激活送余额';
        if (!form.content) throw '请填写使用须知';
        for (let i in opRequired) {
          if (opRequired[i].info.is_select) {
            for (let y in opRequired[i]) {
              if (typeof opRequired[i][y] == 'string' || typeof opRequired[i][y] == 'number') opRequired[i][y] = 1;
            }
          } else {
            for (let y in opRequired[i]) {
              if (typeof opRequired[i][y] == 'string' || typeof opRequired[i][y] == 'number') opRequired[i][y] = 0;
            }
          }
        }
        for (let i in optional) {
          if (optional[i].info.is_select) {
            for (let y in optional[i]) {
              if (typeof optional[i][y] == 'string' || typeof optional[i][y] == 'number') optional[i][y] = 1;
            }
          } else {
            for (let y in optional[i]) {
              if (typeof optional[i][y] == 'string' || typeof optional[i][y] == 'number') optional[i][y] = 0;
            }
          }
        }
        if (form.cover == 1) form.cover_template = 1;
        let coupons_data = [];
        for (let i in selectCouponList) {
          coupons_data.push({
            coupon_id: selectCouponList[i].coupon_id,
            coupon_num: selectCouponList[i].coupon_num,
          });
        }
        form.act_reward.coupons_data = coupons_data;
        form.act_set.require = opRequired;
        form.act_set.norequire = optional;
        return form;
      } catch (e) {
        this.$message({
          message: e,
          type: 'warning',
        });
      }
    },
    zhekouChange(val) {
      if (val > 9.9) {
        this.form.act_reward.goods_idscount_num = 9.9;
      }
    },
    coverChange(val) {
      if (val == 1) {
        this.cover_path = this.cover_template;
      } else {
        this.cover_path = this.form.cover_image;
      }
      console.log(val);
    },
    handleshowAddInfo() {
      this.showAddInfo = !0;
      this.addInfoList = [];
    },
    //添加信息
    pushAddInfoList() {
      this.addInfoList.push({
        name: '',
        require: 1,
        type_val: 1,
        options: [
          {
            label: '是',
            val: 1,
          },
          {
            label: '否',
            val: 0,
          },
        ],
        type: [
          {
            label: '文本',
            val: 1,
          },
          {
            label: '图片',
            val: 2,
          },
        ],
      });
    },
    //添加优惠券
    pushAddCouponList() {
      this.addCouponList.push({
        coupon_id: '',
        coupon_num: '',
      });
    },
    //删除优惠券
    removeAddCouponList(index) {
      let addCouponList = this.addCouponList;
      addCouponList.splice(index, 1);
      this.selectCouponList = addCouponList;
    },
    handleshowAddCoupons() {
      this.addCouponList = JSON.parse(JSON.stringify(this.selectCouponList));
      this.showAddCoupons = !0;
    },
    changeRequired(index) {
      let optional = this.optional;
      let currentInfo = this.opRequired[index];
      if (currentInfo.info.is_select) {
        optional.map(item => {
          if (currentInfo.info.val == item.info.val && item.info.is_select) item.info.is_select = !1;
        });
      }
    },
    changeOptional(index) {
      let opRequired = this.opRequired;
      let currentInfo = this.optional[index];
      if (currentInfo.info.is_select) {
        opRequired.map(item => {
          if (currentInfo.info.val == item.info.val && item.info.is_select) item.info.is_select = !1;
        });
      }
    },
    //删除自定义必填项
    removeOpRequired(index) {
      this.opRequired.splice(index, 1);
      this.selectInfoList.splice(0, 1);
    },
    //删除自定义选填项
    removeOptional(index) {
      this.optional.splice(index, 1);
      this.selectInfoList.splice(0, 1);
    },
    getCoverImg(val) {
      this.form.cover_image = val.path;
      this.cover_path = val.path;
    },
    removeCoverImg() {
      this.form.cover_image = '';
      this.cover_path = '';
    },
    confirmshowAddCoupons() {
      let addCouponList = this.addCouponList;
      for (let i in addCouponList) {
        for (let y in addCouponList[i]) {
          if (!addCouponList[i][y])
            return this.$message({
              message: '请填写完整的优惠券信息',
              type: 'warning',
            });
        }
      }
      this.selectCouponList = addCouponList;
      this.showAddCoupons = !1;
    },
    confirmshowAddInfo() {
      let addInfoList = this.addInfoList;
      for (let i in addInfoList) {
        if (!addInfoList[i].name)
          return this.$message({
            message: '请填写信息',
            type: 'warning',
          });
      }
      for (let i in addInfoList) {
        let key = this.handlePingyin(addInfoList[i].name);
        if (addInfoList[i].require) {
          this.opRequired.push({
            [key]: addInfoList[i].name,
            info: {
              val: addInfoList[i].name,
              type: addInfoList[i].type_val,
              require: 1,
              is_select: !1,
              is_custom: 1,
            },
          });
        } else {
          this.optional.push({
            [key]: addInfoList[i].name,
            info: {
              val: addInfoList[i].name,
              type: addInfoList[i].type_val,
              require: 0,
              is_select: !1,
              is_custom: 1,
            },
          });
        }
      }
      this.selectInfoList.push(...addInfoList);
      this.showAddInfo = !1;
    },
    //中文转拼音
    handlePingyin(val) {
      let pingyin = '';
      let pinyins = pinyin(val, {
        style: pinyin.STYLE_NORMAL,
      });
      for (let y in pinyins) {
        for (let k in pinyins[y]) pingyin = pingyin + pinyins[y][k];
      }
      return pingyin;
    },
    getCouponList() {
      this.$axios.post(this.$api.push.couponList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.couponList = res.result.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card-interface {
  padding: 20px 0 0 30px;
  display: flex;
  justify-content: space-between;
  .interface-box {
    width: 324px;
    height: 620px;
    background: url('https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/back1.png');
    background-size: 324px 620px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    .footer {
      flex: 1;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-between;
        height: 30px;
        border-bottom: 1px solid #dcdfe6;
        i {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .el-button {
        width: 200px;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
    .card-cover {
      height: 160px;
      padding: 0 12px;
      margin-top: 55px;
      .con {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 5px;
        position: relative;
        .con-box {
          position: absolute;
          top: 0;
          left: 12;
          width: 100%;
          height: 100%;
          z-index: 10;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px;
        }
        .el-image {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .card-name {
            display: flex;
            align-items: center;
            color: #7b4509;
            .el-image {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-right: 10px;
            }
            div p:nth-child(1) {
              margin-bottom: 5px;
              font-size: 14px;
            }
            div p:nth-child(2) {
              font-size: 11px;
            }
          }
        }
        .bot {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          color: #7b4509;
          .card-num p:nth-child(1) {
            margin-bottom: 5px;
          }
          span {
            width: 64px;
            line-height: 28px;
            font-size: 11px;
            text-align: center;
            height: 28px;
            background: linear-gradient(139deg, #544e3f 0%, #403c34 24%, #282828 100%);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            opacity: 1;
            border-radius: 36px;
            color: #feeace;
          }
        }
      }
    }
    .card-interest {
      width: 324px;
      height: 180px;
      padding: 0 12px;
      color: #7b4509;
      margin-top: 30px;
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        width: 100;
        padding: 10px;
        li {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 20px;
          font-size: 12px;
          width: 92px;
          .el-image {
            margin-bottom: 8px;
          }
          &:nth-child(3),
          &:nth-child(6) {
            margin-right: 0;
          }
        }
      }
    }
  }
  .form {
    flex: 1;
  }
  .el-form-item {
    max-width: 700px;
    .sp {
      font-size: 14px;
      color: #909399;
    }
  }
  .tips {
    color: #409eff;
    margin-bottom: 20px;
  }
  .card-cover {
    /deep/ .el-radio-group {
      display: flex;
      flex-direction: column;
      .box {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .cover {
          font-size: 14px;
          display: flex;
          .el-image {
            width: 60px;
            height: 30px;
            border-radius: 5px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .card-activation {
    /deep/ .box {
      display: flex;
      margin-bottom: 20px;
      .el-checkbox {
        width: 120px;
      }
      .el-input {
        width: 120px;
        margin-right: 10px;
      }
    }
    .zhekou {
      flex-direction: column;
    }
  }
}
.coupon-content {
  max-height: 400px;
  overflow-y: scroll;
}
.coupon-content,
.info-content {
  text-align: center;
  .add-coupon-list,
  .add-info-list {
    .coupon-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .el-input {
        width: 100px;
        margin-right: 10px;
      }
      i {
        font-size: 20px;
        margin-left: 20px;
      }
    }
    .info-item {
      margin-bottom: 20px;
      .el-input {
        width: 280px;
      }
      .el-select {
        width: 150px;
      }
    }
  }
}
.opRequired,
.optional {
  display: flex;
  align-items: center;
  .opRequired-box,
  .optional-box {
    width: 500px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin-right: 30px;
      position: relative;
      i {
        position: absolute;
        right: -20px;
        top: 0;
        font-size: 18px;
      }
    }
  }
  span {
    flex: none;
    margin-right: 40px;
  }
}
</style>
